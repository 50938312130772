.new-marketplace {
	.banner-wrapper {
		margin-top: 38px;
	}

	.search-section-wrapper {
		margin-top: 28px;
	}

	.course-view {
		.header {
			margin-bottom: 19px;
		}
	}

	.courses-slider {
		.dots {
			display: flex;
			column-gap: 5px;
			width: 100%;
			justify-content: center;
			span {
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background: #d8e0e8;
				&.active {
					background: #2063e3;
				}
			}
		}
	}

	.course-card {
		.course-title {
			font-weight: 600;
		}
	}

	.organisations {
		.organisations-wrapper {
			margin-top: 36px;
			display: flex;
			column-gap: 21px;
			align-items: center;
			padding-bottom: 19px;

			.carousel-wrapper {
				width: calc(100% - ((32px + 21px) * 2));
			        display: none;
                    transition-duration: 3s!important;
				.dots-container-class {
					bottom: -32px;
					> li {
						width: 5px;
						height: 5px;
						> button {
							width: 5px;
							height: 5px;
							background: #d8e0e8;
							border-radius: 50%;
							opacity: 1;
						}
						&.slick-active {
							> button {
								background: #2063e3;
							}
						}
					}
				}
			}

			.organisations-slider-page {
				display: flex !important;
				column-gap: 19px;
			}

			.next-button {
				border: 1px solid #cbd5e0;
				box-sizing: border-box;
				border-radius: 24px;
				background: #ffffff;
				cursor: pointer;
				height: 32px;
				width: 32px;
			}

			.organisation {
				width: 80px;
				> img {
					object-fit: cover;
					width: 80px;
					height: 80px;
					border-radius: 6px;
					box-shadow: 0px 1px 1px rgba(8, 24, 35, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.06);
				}

				.name {
					font-family: Inter;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 20px;

					margin-top: 10px;
					margin-bottom: 0;

					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: center;
				}
			}
		}
	}

	.custom-pagination {
		.ant-pagination {
			display: flex;
			justify-content: center;
			column-gap: 13px;
			> li {
				margin: 0;
			}
		}
		.ant-pagination-item {
			border: 0;
			background: none;
			border-radius: 0;
			min-width: 16px;
			> a {
				width: 16px;
				padding: 0;
			}
			&.ant-pagination-item-active {
				> a {
					border-bottom: 2px solid #2063e3;
				}
			}
		}
		.ant-pagination-next,
		.ant-pagination-prev {
			> button {
				border-radius: 50%;
				line-height: 15px;
				font-size: 10px;
			}
		}
	}
}
