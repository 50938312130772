.materialItem__layout {
	&.materialItem__button-circle {
		border-radius: 50% !important;
	}

	&.materialItem__fixed-widgets {
		position: fixed;
		right: 0px;
		top: 111px;
		z-index: 2;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		cursor: pointer;
	}

	.materialItem__button-over {
		border-radius: 0 !important;
		width: 52px;
		height: 44px;
		border: 1px solid #cbd5e0;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 4px 6px rgba(8, 24, 35, 0.1);
		display: inline-block;

		&.hide {
			display: none;
		}
	}

	.materialItem__icon-over {
		cursor: pointer;
	}

	&.materialItem__sidebar {
		border-left: thin solid #cbd5e0;
		box-shadow: inset 1px 0px 0px #cbd5e0;
		overflow: auto;
	}

	&.materialItem__contentChild {
		overflow: auto;
	}

	&.materialItem__sidebarLayout {
		height: 0;
	}


}

.certificateButton{
	
    
	border: none!important;
    font-weight: 500;
    font-size: 15px;
    position: sticky;
    margin-top: -28px;
    padding-left: 10px;
    /* left: 20px; */
    margin-right: 109px;
	}

	.certificateIcon{
		position: absolute;
		top: 4px;
		left: -15px;;
	}

.progressWrapper {
    height: 8px;
    background: white;
    width: 243px;
    position: fixed;
    right: 69px;
    border-radius: 5px;
    top: 149px;
}


.progressLabel {
	background-color: #2063e3 !important;
    width: 339px;
    height: 83px;
    margin-left: -193px;
    /* margin-top: -54px; */
    padding-top: 30px;
}

.ant-progress-status-success .ant-progress-bg {
    background-color: lightblue;
}

.ant-progress-status-success .ant-progress-bg {
    background-color: lightblue;
}
.ant-layout{
    background-color: #edf2f7;
}
.textProgress{
    color: white;
    font-size: smaller;
    margin-top: -19px;
    right: 47px;
    position: fixed;
}

.barCompleted {
	color:#2063e3;
	width: 80%;
  }

  .label {
	font-size: 12px;
    color: lightblue;
    right: 298px;
    position: fixed;
    top: 118px;
    font-weight: bold;
}

.container {
	background-color: lightblue;
    width: 51px;
    height: 8px;
  }
  .ant-progress-text{
    color: white;
    position: absolute;
    top: -16px;
    left: 1%;
    font-weight: bold;
    font-size: 13px;
	
  }

  .ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 91%;
    height: 4px;
    left: 15px;
    overflow: hidden;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 0px;
  }

  .ant-progress-bg{
	  background-color: lightblue;
  }

  .ant-progress-status-success .ant-progress-text {
    color: chartreuse;
}

.shkarkoButton{
	background-color: #C7E2FF;
    color: #2063E3;
    border: none!important;
    position: absolute;
    top: 72%;
    margin-right: 0%;
    margin-left: 21p;
    margin-left: -157px;
    border-radius: 5px;
    width: 218px;
    height: 27px;

}


.certifikata{

    vertical-align: middle;
    border-style: none;
    position: absolute;
    top: 14%;
    width: 55%;
    left: 3%;
}
.textPjs{
    color: white;
    /* position: fixed; */
    top: 38%;
    left: 12%;
    font-style: italic;
    font-family: auto;
    /* width: 60%; */
    /* font-size: 60px; */
    position: absolute;
    /* font-size: 30px; */
    font-size: 4vw;
    justify-content: center;
}
.textdy{
    color: white;
    top: 45%;
    left: 10%;
    font-family: unset;
    position: absolute;
    font-size: 1.0vw;
    justify-content: center;
}

.tre{
	color: white;
    /* position: fixed; */
    top: 69%;
    left: 29%;
    /* font-style: italic; */
    font-family: unset;
    /* width: 60%; */
    /* font-size: 60px; */
    position: absolute;
    /* font-size: 30px; */
    font-size: 1.0vw;
    
}
.tretre{
    color: black;
    top: 15%;
    left: 69%;
    font-size: 64px;
    position: absolute;
    font-size: 1.3vw;

}
.loggedInMenu__fullnameE{
    position: relative;
    top: -48px;
    left: -75px;
    
}

.trekater{
    color: black;
    top: 33%;
    left: 69%;
    font-size: 64px;
    position: absolute;
    font-size: 1.4vw;


}

.username{
	position: absolute;
    top: 27%;
    left: 75%;
}
.lasttext{
    word-break: break-word;
    position: absolute;
    top: 105%;
    left: 38px;
    font-weight: 400;
    font-family: 'Inter';
    font-size: 12px;
}

.courseItem{
    width: 22%;
    left: 69%;
    top: 25%;
    position: absolute;
}
.color-black{
 
    margin-top: 230%;
    position: absolute;
    color: black;
    font-size: initial;
    left: 3%;
}
.color-gray1{
    position: absolute;
    margin-top: 251%;
    font-size: small;
    left: 3%;
}
.color-gray2 {
    position: absolute;
    margin-top: 264%;
    font-size: small;
    left: 3%;
}
.shkarko{
    position: absolute;
    margin-top: 292%;
    left: 2%;
    width: 127px;
}

.ndaje{
    position: absolute;
    top: 94%;
    left: 80%;
    background: #2063E3;
    color: white;
    height: 36px;
    width: 107px;
}
.claas{
    position: absolute;
    margin-top: 321%;
    left: 3%;
    font-size: small;
  
}
.iconsS{
    margin-inline: 5px;
}
.ant-modal-body{
    padding: 45px;
    height: 239px;
    padding-left: 99px;
}
.ant-modal-title{
    padding-left: 27%;
}
.linklink{
    position: absolute;
    top: 57%;
    left: 36%;
}
.inputlink{
    position: absolute;
    top: 70%;
    width: 295px;
    left: 13%;

   
}
.new{
    position: absolute;
    top: 94%;
    left: 70%;
    background-color: #FFFFFF;
    border: 1px solid #CBD5E0;
    width: 130px;
    height: 34px;
    border-radius: 6px;
    color:  #4A5568;


}
.teksti{
    word-break: break-word;
    left: 3%;
    position: absolute;
    font-weight: 800;
    font-style: inherit;
    font-size: small;
}
.lastclass{
    position: absolute;
    left: 70%;
    font-size: inherit;
}
.icondownload{
   
    left: 5px;
    position: absolute;
    top: 6px;
}
.iconshare{
    position: absolute;
    left: 6px;
    top: 6px;
}

.new:hover {background-color: lavender}